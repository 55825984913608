import { useRouter } from 'next/router'
import useSports from '@ssgat/common/providers/useSports'
import { Featured, Main } from 'components/Layout/Layout'
import {
    NextUpContainer,
    nextUpContainerProps,
    NextUpContainerType,
} from 'components/NextUpContainer'
import HeaderContainer from 'components/HeaderContainer'
import { HeroContainer, heroContainerProps } from 'components/HeroContainer'
import { ContentContainer } from 'components/ContentContainer'
import {
    BestBetsContainer,
    bestBetsContainerProps,
} from 'components/BestBetsContainer'
import { NextPageWithLayout } from 'types/nextPageWithLayout'
import {
    ParlaysContainer,
    parlaysContainerProps,
} from 'components/ParlaysContainer/ParlaysContainer'
import SportPredictionsContainer, {
    sportPredictionsContainerProps,
} from 'components/Predictions/SportPredictionsContainer'
import {
    EditorialGridContainer,
    editorialGridContainerProps,
} from 'components/EditorialGridContainer/EditorialGridContainer'
import { getAnalytics } from 'project/analytics'
import Strings from 'project/localization'
import { nextReduxWrapper } from 'components/util/nextReduxWrapper'
import getActiveSports from 'project/getActiveSports'
import { ServerSidePageProps } from 'types/serverSidePageProps'
import { GetServerSidePropsContext } from 'next'
import { combineNextPromiseActions } from 'project/nextPromiseAction'
import Error from 'components/Error'
import ssrGetPageSlug from 'project/ssrGetPageSlug'

const nextUpProps: NextUpContainerType['nextUpProps'] = {
    filterTitle: 'Next Up',
    showFilter: false,
    showCardHeader: false,
    borderStyleSmooth: false,
}
// eslint-disable-next-line @typescript-eslint/ban-types
type SportHomePageType = {
    error?: boolean
}

const SportHomePage: NextPageWithLayout<SportHomePageType> = ({ error }) => {
    const { query } = useRouter()
    const { getSport } = useSports()
    const sportName = getSport(`${query.sport}`)?.name
    if (error) return <Error />
    return (
        <>
            <Featured>
                <HeaderContainer />
                <NextUpContainer nextUpProps={nextUpProps} />
                <HeroContainer />
            </Featured>
            <Main>
                <ContentContainer>
                    <BestBetsContainer
                        title={`${Strings.formatString(
                            Strings.sportBestBets,
                            `${sportName}`
                        )}`}
                    />
                    <ParlaysContainer
                        title={`${Strings.formatString(
                            Strings.sportParlays,
                            `${sportName}`
                        )}`}
                    />
                    <SportPredictionsContainer />
                    <EditorialGridContainer />
                </ContentContainer>
            </Main>
        </>
    )
}

export const getServerSideProps = nextReduxWrapper.getServerSideProps(
    (store) =>
        async (
            ctx: GetServerSidePropsContext
        ): Promise<ServerSidePageProps<SportHomePageType>> => {
            const pageSlug = ssrGetPageSlug(ctx)
            const sports = await getActiveSports()
            const sport = sports.find((s) => s.slug === ctx.params?.sport)
            if (!sport) {
                return {
                    props: {
                        error: true,
                        // only following this here, as the withAnalytics wrapper doesn't expost res object.
                        pageAnalytics: getAnalytics({
                            page_name: 'Error Page',
                            status: `${ctx.res.statusCode}`,
                        }),
                        jsonLd: [],
                    },
                }
            }
            return {
                props: {
                    jsonLd: await combineNextPromiseActions([
                        heroContainerProps(store, pageSlug),
                        bestBetsContainerProps(store, pageSlug),
                        nextUpContainerProps(store, pageSlug),
                        sportPredictionsContainerProps(store, pageSlug),
                        parlaysContainerProps(store, pageSlug),
                        editorialGridContainerProps(store, pageSlug),
                    ]),
                    pageAnalytics: getAnalytics({
                        category: sport?.name,
                        page_name: 'Sport Home',
                        site_section: sport?.name,
                        sport: sport?.name,
                    }),
                },
            }
        }
)

export default SportHomePage
