import useSports from '@ssgat/common/providers/useSports'
import { Section } from 'components/Layout/Layout'
import useSportPredictions from '@ssgat/common/providers/useSportPredictions'
import {
    CardGrid,
    EventCard,
    EventGrid,
    EventShortcutCard,
    NoBettingContent,
    SubHeader,
} from '@ssgat/react-components'
import usePageSlug from 'components/util/usePageSlug'
import { useRouter } from 'next/router'
import styles from './predictions.module.scss'
import {
    combineNextPromiseActions,
    nextPromiseAction,
} from 'project/nextPromiseAction'
import { ContainerPropsType } from 'types/containerPropsType'
import { FC } from 'react'

import Strings from 'project/localization'

export type SportPredictionsContainerType = {
    expanded?: boolean
}

const SportPredictionsContainer: FC<SportPredictionsContainerType> = ({
    expanded,
}) => {
    const { sportPredictions } = useSportPredictions()
    const { query } = useRouter()
    const pageSlug = usePageSlug()
    const { getSport } = useSports()
    const sport = getSport(`${query.sport}`),
        sportSlug = sport?.slug,
        sportName = sport?.name

    const predictions = sportPredictions?.[pageSlug]
    if (!predictions?.length) {
        return expanded ? (
            <Section mobileOrder={0}>
                <NoBettingContent
                    sport={`${query?.sport}`}
                    keyword={Strings.picks}
                />
            </Section>
        ) : null
    }
    return expanded ? (
        <>
            {predictions.map(({ predictions: events, title }) =>
                events[0].teamBased ? (
                    <div className={styles.eventGrid} key={title}>
                        <EventGrid
                            title={title}
                            events={
                                // todo: react-components EventCardFull type assumes homeTeam and awayTeam can't be undefined which is wrong
                                events as any
                            }
                            displayEventDetails={true}
                        />
                    </div>
                ) : (
                    events.map((event) => (
                        <div key={event.id} className={styles.eventGrid}>
                            <EventShortcutCard {...event} wide={true} />
                        </div>
                    ))
                )
            )}
        </>
    ) : (
        <Section toMobileEdge>
            <SubHeader
                title={`${Strings.formatString(
                    Strings.sportPredictions,
                    `${sportName}`
                )}`}
                href={`/${sportSlug}/predictions`}
            />
            {predictions.map((prediction) => {
                const { title, predictions, predictionCount } = prediction

                const single = predictions?.length === 1
                const [firstPrediction] = predictions
                const isTeamSport = firstPrediction?.teamBased

                return isTeamSport ? (
                    <div className={styles.eventGrid} key={title}>
                        <EventGrid
                            events={
                                // todo: react-components EventCardFull type assumes homeTeam and awayTeam can't be undefined which is wrong
                                predictions as any
                            }
                            title={title}
                            displayEventDetails={true}
                        />
                    </div>
                ) : (
                    <CardGrid
                        key={title}
                        title={title}
                        mobileSlider={true}
                        featuredCard={!isTeamSport && single}
                        href={firstPrediction.href}
                        seeAllButton={true}
                        seeAllButtonText={`${Strings.formatString(
                            Strings.seeAllWithNumber,
                            `${predictionCount}`
                        )}`}
                    >
                        {predictions?.map((sportPrediction) => {
                            const { id } = sportPrediction
                            return isTeamSport ? (
                                <EventCard
                                    {
                                        // todo: react-components EventCardFull type assumes homeTeam and awayTeam can't be undefined which is wrong
                                        ...(sportPrediction as any)
                                    }
                                    key={id}
                                />
                            ) : (
                                <EventShortcutCard
                                    {...sportPrediction}
                                    wide={single}
                                    key={id}
                                />
                            )
                        })}
                    </CardGrid>
                )
            })}
        </Section>
    )
}

export const sportPredictionsContainerProps: ContainerPropsType = async (
    store,
    pageSlug
) => {
    return {
        jsonLd: await combineNextPromiseActions([
            nextPromiseAction<'getSportPredictions'>(
                store,
                'getSportPredictions',
                {
                    pageSlug,
                }
            ),
        ]),
    }
}

export default SportPredictionsContainer
