import { FC } from 'react'
import { Header } from '@ssgat/react-components'
import useContent from '@ssgat/common/providers/useContent'
import { useRouter } from 'next/router'

// eslint-disable-next-line @typescript-eslint/ban-types
export type HeaderContainerType = {}

const HeaderContainer: FC<HeaderContainerType> = () => {
    const { asPath } = useRouter()
    const { content } = useContent()
    const header = content?.[asPath]?.[0]?.intro?.title?.toString()
    if (!header) return null
    return <Header title={header} />
}

export default HeaderContainer
