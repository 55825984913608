import React from 'react'
import { NoBettingContent } from '@ssgat/react-components'
import { Main } from 'components/Layout/Layout'

const Error: React.FC = () => {
    const reasoning = ''
    const bullets = [
        {
            href: `/news/`,
            linkText: `News`,
            bulletText: '- Discover the latest news across multiple sports.',
        },
        {
            href: '/sportsbooks/best/',
            linkText: 'Bonuses',
            bulletText:
                '- Check out the latest selection of offers from your favorite Sportsbooks.',
        },
    ]
    return (
        <Main>
            <NoBettingContent
                heading={
                    'Oops, something’s gone wrong. Please try again, or head to the Home page.'
                }
                reasoning={reasoning}
                bullets={bullets}
                sport=""
                keyword=""
            />
        </Main>
    )
}

export default Error
