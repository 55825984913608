
import { useDispatch, useSelector } from 'react-redux';
import { AppActions, Callbacks } from '../app-actions';
import { AppState, RequestTypes } from "../state-type";
import { useCallback } from 'react';

type UseSportPredictions = {
  sportPredictions: AppState['sportPredictions'],
  sportPredictionsLoading: AppState['sportPredictionsLoading'],
  sportPredictionsError: AppState['sportPredictionsError'],
  getSportPredictions: (data:RequestTypes['getSportPredictions'], callbacks?:Callbacks)=>void,
}

export default function useSportPredictions():UseSportPredictions {
  const {
    sportPredictions, sportPredictionsLoading, sportPredictionsError } = useSelector((state:AppState)=>({
    sportPredictions: state.sportPredictions,
    sportPredictionsLoading: state.sportPredictionsLoading,
    sportPredictionsError: state.sportPredictionsError,
  }));
  const dispatch = useDispatch();
  const getSportPredictions = useCallback((data:RequestTypes['getSportPredictions'], callbacks?:Callbacks)=>{
    return dispatch(AppActions.getSportPredictions(data, callbacks))
  },[dispatch])
  return {
    sportPredictions,
    sportPredictionsLoading,
    sportPredictionsError,
    getSportPredictions,
  }
}
